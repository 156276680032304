<template>
    <div>
        <BreadcrumbComponent :items=items></BreadcrumbComponent>

        <v-container class="custom-container">
            <input 
                v-model="query" 
                outlined dense 
                @input="search" 
                class="form-control my-3 py-3 py-4 text-uppercase"
                placeholder="Entrer le numero de la fiche" 
            />
        </v-container>

        <v-container>
            <PreloaderComponent :enable=enable></PreloaderComponent>

            <template v-if="query">
                <div class="row">
                    <div class='col-md-12' style="padding-top:0px">
                        <div class='table-responsive'>
                            <table class="table table-striped table-hover table-bordered overflow-hidden">
                                <thead>
                                    <tr>
                                        <th style="text-align:left">#</th>
                                        <th>MAGASIN</th>
                                        <th>AGENT</th>
                                        <th>CLIENT</th>
                                        <th>MONTANT</th>
                                        <th>DATE</th>
                                        <th>HEURE</th>
                                        <th>STATUT</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody style="margin-top:40px">
                                    <tr v-if="results.length == 0">
                                        <td colspan="9" style="text-align:center">
                                            Aucun résultat pour le numéro de fiche <strong>"{{ query.toUpperCase() }}"</strong>
                                        </td>
                                    </tr>

                                    <template v-else>
                                        <tr v-for="sale in results" :key="sale.id">
                                            <td style="text-align:left">{{ sale.sale_number }}</td>
                                            <td>{{ sale.store.name }}</td>
                                            <td>{{ sale.user.full_name }}</td>
                                            <td>{{ sale.customer.full_name }}</td>
                                            <td>{{ sale.total | formatNumber }} {{ sale.rate.name }}</td>
                                            <td>{{ sale.created_at | formatDate }}</td>
                                            <td>{{ sale.created_at | formatTime }}</td>
                                            <td>
                                                <span v-if="sale.status == true"
                                                    class='badge badge-success'>Actif</span>
                                                <span v-if="sale.status == false"
                                                    class='badge badge-danger'>Annulé</span>
                                            </td>
                                            <td style="text-align:right">
                                                <v-icon small class="mr-2 view_button" @click="view(sale.id)">
                                                    mdi-eye-outline
                                                </v-icon>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else >
                <v-container>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <h3>Aucune recherche effectuée</h3>
                            <p>Entrez le numéro de la fiche pour effectuer une recherche</p>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-container>
    </div>
</template>

<script>
import PreloaderComponent from '../components/PreloaderComponent.vue'
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'

export default {
    name: 'TicketSearch',
    methods: {
        view: function (id) {
            this.$router.push('/sales/' + id)
        },
        search() {
            const searchQuery = this.query.trim().toUpperCase()

            if (searchQuery.length > 0) {
                this.enable = false

                const self = this

                this.$axios.get('/sales?orderdesc=created_at&include=user,store,customer,rate&sale_number=' + searchQuery)
                    .then(function (response) {
                        self.results = response.data
                        self.enable = true
                    }).catch(function () {
                        self.enable = true
                    })

            } else {
                this.results = []
            }
        }
    },
    data: () => ({
        query: '',
        results: [],
        enable: true,
        items: [
            {
                text: 'ACCUEIL',
                disabled: false,
                href: '/',
            }
        ],
    }),
    components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style scoped>
td,
th {
    text-align: center;
}

.custom-container {
    max-width: 600px !important;
    margin: 0 auto;
}

.view_button {
    font-size: 16px;
    padding: 4px 4px 4px 4px;
    background: #fb8c00 !important;
    color: white !important;
    border-radius: 4px;
}
</style>